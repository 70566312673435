/* UserTable.css */

.user-table-container {
  max-width: 1400px;
  margin: 20px auto;
  overflow-x: auto; /* Add horizontal scrolling */
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.user-table th {
  background-color:#aed238;
}

.add-user-button {
  background-color:#a4c639;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-user-button:hover {
  background-color: rgb(73, 172, 78);
}

.delete-button, .edit-button {
  border: none;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-right: 5px;
  cursor: pointer;
}

.delete-button {
  background-color: #DC4C64;
  width: 70px;
}

.edit-button {
  background-color: #a4c639;
  width: 70px !important;
  text-decoration: none;
}

.edit-button:hover, .delete-button:hover {
  background-color: #aed238;
}

.error-message {
  color: red;
  margin: 0px;
  font-size: 13px;
  position: relative;
  bottom: 3px;
  left: 3px;
}

.fontBold {
  font-weight: bold;
}

/* Responsive styles */

@media only screen and (max-width: 600px) {
  .user-table th, .user-table td {
    padding: 4px;
    font-size: 8px;
  }

  .add-user-button, .delete-button, .edit-button {
    padding: 5px;
    font-size: 8px;
    margin-left: 20px;
  }
  .delete-button ,.edit-button{
    width: 55px!important;
  }
}

@media only screen and (max-width: 400px) {
  .user-table-container {
    overflow-x: scroll;
  }
}
