input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s; /* Add a long transition to prevent quick color change */
}

.Main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.edit{
  margin-top: 80px !important;
  padding: 50px !important;
}
.section {
  /* background-image: url(../Images//background-1.jpg); */
  background-image: url(../../Assets/Images/background.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #5759bbcc;
  
  background-position: center;
  background-size: cover;
}
/* .section {
   background-image: url(../../Images//background-1.jpg);
   border: 2px solid blue; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #5759bbcc; 
  background-position: center;
  background-size: cover;
} */

.form-box-main {
  position: relative;
  width: 600px;
  height: 700px;
  /* background-color: #5758BB; */
  background-color: rgba(255, 255, 255, 0.384) !important;
  border: 1px solid black;
  border-radius: 10px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 20px black;
}
.form-box-user {
  position: absolute;
  top: 30px;
  width: 550px;
  height: 650px;
  /* background-color: #5758BB; */
  background-color: rgba(255, 255, 255, 0.384) !important;
  border: 1px solid black;
  border-radius: 10px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.names {
  display: flex;
  justify-content: space-evenly;
}

.role-type select {
  width: 100%; /* Make the select box take full width */
  max-width: 600px;
  height: 30px;
  margin: 20px;
  position: relative;
  left: -20px;
  border-radius: 10px;
  height: 40px;
}

.heading {
  font-size: 2em;
  color: black;
  text-align: center;
}

.inputbox {
  position: relative;
  margin: 30px 0;
  width: 100%; /* Make the input box take full width */
  max-width: 310px;
  border-bottom: 2px solid black;

}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.second-section {
  width: 100%; /* Make the second section take full width */
  max-width: 650px;
}
  
  .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: black;
    font-size: 1em;
    pointer-events: none;
    transition: 0.5s;
  }
  input:focus ~ label,
  input:valid ~ label {
    top: -5px;
  }
  .inputbox input {
    width: 100%;
    height: 50px;
    background: transparent !important;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px;
    color: black;
  }
  .inputbox input::-ms-value{
    background-color: transparent !important;
  }
  
  .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: black;
    font-size: 1.2em;
    top: 20px;
  }
  .forget {
    margin: -15px 0 15px;
    font-size: 0.9em;
    color: black;
    display: flex;
    justify-content: space-between;
  }
  
  .forget label input {
    margin-right: 3px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .forget label a {
    color: blue;
    text-decoration: none;
  }
  .forget label a:hover {
    text-decoration: underline;
  }
  .btn-login {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: #a4c639;
    
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
  }
  .create{
    border-radius: 10px;
  }
  .create:hover{
    border: 1px solid black;
    box-shadow: 1px 1px 2px black;

  }
  .register {
    font-size: 0.9em;
    color: black;
    background-color: tra;
    text-align: center;
    margin: 25px 0 10px;
  }
  .register p a {
    text-decoration: none;
    color: blue;
    font-weight: 600;
  }
  .register p a:hover {
    text-decoration: underline;
  }
  
  
/* Responsive styles */

@media (max-width: 768px) {
  .form-box-main,
  .form-box-user {
    width: 90%;
    font-size: 13px;

  }
}

@media (max-width: 480px) {
  .form-box-main,
  .form-box-user {
    width: 90%;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .form-box-main {
    width: 90%; /* Adjust the width for smaller screens */
    max-width: none;
  }

  .role-type select {
    max-width: 250px; /* Remove max-width for select box on smaller screens */
  }

  .inputbox {
    max-width: none; /* Remove max-width for input box on smaller screens */
  }

  .second-section {
    max-width: none; /* Remove max-width for second section on smaller screens */
  }
}

@media (max-width: 480px) {
  .form-box-main {
    width: 90%; /* Adjust the width for even smaller screens */
    padding: 30px;
    
  }
}



/* styles.css */

.success-animation {
  margin: 150px auto;
  /* background-color: rgba(0, 0, 0, 0.204); */
}

.checkmark {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  /* position: absolute;
  top: 150px;
  right: 160px; */
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

/* Add the blur effect to the background */
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 background-color: rgba(0, 0, 0, 0.318);
  z-index: 99;
}
.error-message-red {
  color: red;
}
