
body {
    --sb-track-color: #ffffff;
    --sb-thumb-color: #a4c639;
    --sb-size: 12px;
  
    scrollbar-color: var(--sb-thumb-color) 
                     var(--sb-track-color);
  }
  
  body::-webkit-scrollbar {
    width: var(--sb-size) 
  }
  
  body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 12px;
  }
  
  body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 12px;
    
    }