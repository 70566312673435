.navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}

.container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
   
}


.navbar{
    /* box-shadow: 0px 5px 10px 0px #aaa; */
    position: fixed;
    width: 100%;
    background: #a4c639;
    /* color: #000; */
    opacity: 0.85;
    z-index: 100;
    
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    height: 60px;
    
    align-items: center;
}

.menu-items{
    order: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 2px solid red; */

}

.menu-items li{
    list-style: none;
    margin-left: 40px;
    margin-top: 10px;
    font-size: 1.3rem;
}

.logout-li{
    /* border: 1px solid #038cc4; */
    padding: 6px;
    /* border-radius: 17px; */
    margin: 3px!important;
    margin-left: 20px;
    cursor: pointer;
}
.logout-li a{
    border: none;
    color: #038cc4!important;
    background: transparent;
}
.navbar a{
    color: black;
    text-decoration: none;
    font-size: large;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}
.logo-main{
   position: absolute;
   top: 2px;
   left: 20px;
   height: 70px;
   filter: drop-shadow(1px 2px 50px white);
}

.navbar a:hover{
    color:black;
    /* border: 1px solid black; */
    text-decoration: underline;
    /* border-radius: 10px; */
    /* padding: 8px; */

}

@media (max-width: 768px){
    .navbar{
        opacity: 1.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }
    .logo-main{
        left: 80px;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 50px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 100px;
        background: #fff;
        height: 100vh;
        max-width: 950px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 40px;
        transition: transform 0.5s ease-in-out;
        box-shadow:  5px 0px 10px 0px #aaa;
        overflow: scroll;
    }
    

    .navbar .menu-items li{
        margin-bottom: 1.8rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

 
    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
    .navbar a{
      color: black;
    }
}







/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .menu-items{
        justify-content: flex-start;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .menu-items li{
        list-style: none;
        margin-left: 25px;
        margin-top: 10px;
        font-size: 12px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .navbar a {
        font-size: 14px !important;
    }
    .container{
        max-width: 100%!important;
        padding-left: 70px!important;
    }
    .menu-items li{
        list-style: none;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 15px;
    }
    .menu-items{
        /* padding-left: 4rem!important; */
    }
  }

  @media only screen and (min-width: 993px) and (max-width: 1199px) {

  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {

  }

/* home page css here  */

