/* General Styles */
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background-color: #a4c639;
  background-image: url("../../Assets/Images/background-1.jpeg");
  background-size: cover;
  background-position: center;
  padding: 20px;
  box-sizing: border-box;
}

.left-section,
.right-section {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-section img {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(3px 3px 10px white);
}

.right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #000;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  box-shadow: 2px 2px 20px #000;
}

.login-heading {
  text-align: center;
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 12px 10px;
  border: none;
  border-bottom: 2px solid black;
  background: transparent;
  outline: none;
  font-size: 1rem;
  color: black;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 1.2rem;
  cursor: pointer;
}

.login-btn {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #a4c639;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #8cb236;
}

.register-text {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 15px;
}

.register-text a {
  color: blue;
  text-decoration: none;
}

.register-text a:hover {
  text-decoration: underline;
}

/* Modal Styles */
.login-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e6d799;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.login-modal-close-btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #a4c639;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-modal-close-btn:hover {
  background-color: #8cb236;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .left-section {
    max-width: 100%;
    order: 1; /* Show image first */
  }

  .right-section {
    max-width: 100%;
    order: 2; /* Show card second */
  }

  .login-form-container {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .login-heading {
    font-size: 1.5rem;
  }

  .login-btn {
    font-size: 0.9rem;
  }
}
