.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent !important; /* Set background color to transparent */
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    bottom: 0;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: rgba(164, 198, 57, 1); /* Set your desired color with alpha for transparency */
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    bottom: calc(50% - 16px);
    left: calc(50% - 6.8px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
    0%, 80%, 100% {
      opacity: .75;
      box-shadow: 0 0 black;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px black;
      height: 40px;
    }
  }
  
  @keyframes loading-keys-app-loading {
    0%, 80%, 100% {
      opacity: .75;
      box-shadow: 0 0 black;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px black;
      height: 40px;
    }
  }
  