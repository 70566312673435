@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

.main-Div {
  font-family: 'Open Sans', sans-serif;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 3em 1em;
}

.accordion {
  max-width: 420px;
  text-align: left;
}

article {
  border: 1px solid #fff;
  padding: 1em;
  margin: 1em auto;
}

input[type="radio"] {
  appearance: none;
  position: fixed;
  top: -100vh; left: -100vh;

  & ~ div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &:checked ~ div {
    max-height: 200px;
  }
}



.form-div{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-div span{
margin: 0%;
margin-top: 10px;
}





.progress-bar {
  display: flex!important;
  flex-direction: row!important;
  justify-content: space-between!important;
  margin-bottom: 20px;
}

.step {
  width: 30px;
  height: 30px;
  /* border: 2px solid #ccc; */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  color: #555;
  background-color: #fff;
  z-index: 1;
}

.active {
  /* background-color: #198754; */
  color: #ffc107;
  /* border-color: #198754; */

}

.completed {
  /* background-color: #4CAF50; */
  color: #4CAF50;
  border-color: #4CAF50;
}


@media screen and (max-width: 768px) {
  .progress-bar {
    flex-direction: column !important;
    align-items: center;
  }

  .step {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .step {
    font-size: 16px;
    width: 20px;
    height: 20px;
  }
}





.family-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.family-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 10px;
  /* font-size: 19px; */
  /* text-align: center; */
  width: 100%;
  margin: auto;
}
