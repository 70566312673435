.tab-titles {
    display: flex;
    margin: 20px 0 40px;
  }
  
  .tab-links {
    margin-right: 100px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(153, 153, 153);}

.form-input {
  padding: 5px;
  margin: 5px 0px;
  border-radius: 5px;
  border: 2px solid lightgrey;
}

.body {
  background-color: #866aea;
  font-family: "Arial";
  padding: 2em 6em;
}

.acc-kontainer {
  width: 100%;
  margin: auto;
}
.acc-kontainer .acc-body {
  width: 98%;
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 0;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.2);
  line-height: 28px;
  padding: 0 20px;
  box-sizing: border-box;
  transition: 0.5s;
}

.acc-kontainer label {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  padding: 15px;
  width: 100%;
  color: black;
  font-weight: 400;
  box-sizing: border-box;
  z-index: 100;
  background-color: #4CAF50;
}

.acc-kontainer input[type="radio"] {
  display: none;
}

.acc-kontainer label:before {
  font-family: "FontAwesome";
  content: "\f067";
  font-weight: bolder;
  float: right;
}

.acc-kontainer input:checked + label {
  background-color: #008000;
  color: #fff;
  font-weight: bold;
}

.acc-kontainer input:checked + label:before {
  font-family: "FontAwesome";
  content: "\f00d";
  transition: 0.5s;
  color: #fff;
}

.acc-kontainer input:checked ~ .acc-body {
  height: auto;
  color: black;
  font-size: 16px;
  padding: 20px;
  transition: 0.5s;
  background-color: #4caf5080;
  overflow-y: auto;
}

/* New Patient Details Css End */

/* new accordian code started */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* .main-Div {
  font-family: 'Open Sans', sans-serif;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 3em 1em;
} */

.accordion {
  max-width: 420px;
  text-align: left;
}

article {
  border: 1px solid #fff;
  padding: 1em;
  margin: 1em auto;
}

input[type="radio"] {
  appearance: none;
  position: fixed;
  top: -100vh;
  left: -100vh;

  & ~ div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
  .tab-links:active {
    background-color: #4caf50;
    border: 2px solid;
  }
  
  .tab-links::after {
    content: "";
    width: 0;
    height: 5px;
    background-color: #4caf50;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
  }
  
  .tab-links.active-link::after {
    width: 100%;
  }
  
  .tab-contents {
    display: none;
  }
  
  .tab-contents.active-tab {
    display: block;
  }
  
  .mainForm{
    width: 80%;
    margin: auto;
  }
  #mainForm{
    width: 80%;
    margin: auto;
  }
  .form-div{
      display: flex;
      flex-direction: column;
      /* width: 80%; */
    }
  
  .form-input {
    padding: 5px;
    margin: 5px 0px;
    border-radius: 5px;
    border: 2px solid lightgrey;
  }
  
  .body {
    background-color: #866aea;
    font-family: "Arial";
    padding: 2em 6em;
  }
  
  .acc-kontainer {
    width: 100%;
    margin: auto;
  }
  .acc-kontainer .acc-body {
    width: 98%;
    width: calc(100% - 20px);
    margin: 0 auto;
    height: 0;
    color: rgba(0, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0.2);
    line-height: 28px;
    padding: 0 20px;
    box-sizing: border-box;
    transition: 0.5s;
  }
  
  .acc-kontainer label {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    padding: 15px;
    width: 100%;
    color: black;
    font-weight: 400;
    box-sizing: border-box;
    z-index: 100;
    background-color: #4CAF50;
  }
  
  .acc-kontainer input[type="radio"] {
    display: none;
  }
  
  .acc-kontainer label:before {
    font-family: "FontAwesome";
    content: "\f067";
    font-weight: bolder;
    float: right;
  }
  
  .acc-kontainer input:checked + label {
    background-color: #008000;
    color: #fff;
    font-weight: bold;
  }
  
  .acc-kontainer input:checked + label:before {
    font-family: "FontAwesome";
    content: "\f00d";
    transition: 0.5s;
    color: #fff;
  }
  
  .acc-kontainer input:checked ~ .acc-body {
    height: auto;
    color: black;
    font-size: 16px;
    padding: 20px;
    transition: 0.5s;
    background-color: #4caf5080;
    overflow-y: auto;
  }
  
  /* New Patient Details Css End */
  
  /* new accordian code started */
  
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
  
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  /* .main-Div {
    font-family: 'Open Sans', sans-serif;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 3em 1em;
  } */
  
  .accordion {
    max-width: 420px;
    text-align: left;
  }
  
  article {
    border: 1px solid #fff;
    padding: 1em;
    margin: 1em auto;
  }
  
  input[type="radio"] {
    appearance: none;
    position: fixed;
    top: -100vh;
    left: -100vh;
  }
    & ~ div {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;
    }
  
    &:checked ~ div {
      max-height: 400px;
    }
}
.tab-titles {
  display: flex;
  margin: 20px 0 40px;
}

.tab-links {
  margin-right: 100px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgb(153, 153, 153);

}
.tab-links:active{
  background-color: #4caf50;
  border: 2px solid;
}

.tab-links::after {
  content: "";
  width: 0;
  height: 5px;
  background-color: #4caf50;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}

.tab-links.active-link::after {
  width: 100%;
  
}

.tab-contents {
  display: none;
}

.tab-contents.active-tab {
  display: block;
}








.form-div{
  display: flex;
  flex-direction: column;
}

.form-input {    
  padding: 5px;
  margin: 5px 0px;
  border-radius: 5px;
  border: 2px solid lightgrey;
  width: 100%;
}
.family-input {    
  padding: 5px;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
  border: 2px solid lightgrey;
}
.full-width-btn{
  background-color: #a4c639;
  color: white;
  padding: 8px;
  border-radius: 10px;
  margin-top: 20px;
  width: 50%;
}
.nextBtn{
  background-color: #a4c639;
  color: white;
  margin-top: 20px;
  width: 50%;
}
.previewBtn{
  background-color: #a5c639b4;
  color: white;
  margin-top: 20px;
  width: 50%;
}
.deleteBtn{
  background-color: red;
  color: white;
  border: none;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  margin-top: 34px;
  vertical-align: bottom;
}
.addFamilyBtn{
  background-color: #a4c639;
  color: white;
  border: none;
  /* width: 100%; */
  padding: 5px;
  border-radius: 5px;
  /* margin-top: 31px; */
  vertical-align: bottom;
}











/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .previewBtn{
    width: 100%;
  }
  .nextBtn{
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .previewBtn{
    width: 100%;
  }
  .nextBtn{
    width: 100%;
  }
}