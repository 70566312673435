/* RegisteredPatients.css */

.maincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  /* background-image: url('../../Assets//Images/mainbodylogo.jpeg' ); */
  /* opacity: 1.5; */
  background-size: cover;
  background-attachment: fixed; /* This ensures that the background remains fixed */
}

.patient-card {
  border-radius: 8px;
  /* margin: 20px 0px; */
  padding: 15px 30px;
  /* Use full width on smaller screens */ /* Limit max width for larger screens */
  box-shadow: 1px 1px 8px black;
  background-color: #ffffffee;
  width: 80%;
}

.patient-table {
  font-size: 20px;
  width: 100%;
  /* margin: 10px 20px; */
  position: relative;
}

.patient-data {
  margin-bottom: 20px;
}

.file-upload-or-not {
  display: flex;
  align-items: center;
}

.file-upload-logo {
  width: 20px;
  height: 20px;
  position: relative;
  right: -600px;
  /* margin-right: 5px; */
}

.file-upload,
.file-not-found {
  font-weight: bold;
}
.card-file-upload,
.card-file-not-found {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  right: 550px;
  position: relative;
  right: -170px;
}

.data-btn {
  display: flex;
  flex-direction: row;
  /* margin-top: 20px; */
  width: 100%;
  justify-content: space-evenly;
  /* align-items:center; */
}

.btn-register-more,
.btn-register-status,
.btn-patient-delete {
  background-color: #a4c639;
  border-radius: 10px;
  color: white;
  border: none;
  width: 20%;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 0; /* Adjusted margin for smaller screens */
  cursor: pointer;
}
.btn-patient-delete {
  background-color: #dc4c64;
}
.btn-register-more:hover,
.btn-register-status:hover {
  background-color: #a4c639;
}

.btn-register-more.active {
  background-color: grey;
}

.btn-register-status.active {
  /* Additional styles for the "Status" button when active */
}

.btn-close-submit {
  margin-top: 20px;
}

.btn-register-edit {
  /* background-color: #ff0000;
  border-radius: 10px;
  color: white;
  border: none;*/
  /* width: 5%; */
  /* padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 0; Adjusted margin for smaller screens */
  cursor: pointer;
  position: absolute;
  top: 0%;
  right: 8%;
}

.btn-register-edit img {
  width: 30px;
  position: absolute;
  right: 3%;
}
.status-bar {
  display: flex;

}
.patient-id {
  display: flex;
  align-items: baseline;
}
.patientid {
  color: #a4c639;
  font-size: 20px;
  font-weight: 600;
}

.btn-register-status {
  background-color: #a4c639;
}
.submit {
  margin-top: 50px;
  position: relative;
  left: 450px;
}

/* .patient-details-sidebar,
.patient-status-sidebar {
  position: fixed;
  top: 0;
  right: -100%; Initially off-screen
  height: 100%;
  width: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  z-index: 2;
  transition: right 0.3s ease-in-out;
} */

.patient-details-sidebar,
.patient-status-sidebar {
  width: 100%;
  position: relative;
}

.close-icon {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 0%;
  right: 0%;
}

.table-heading {
  margin-top: 20px;
}

table {
  /* border: 1px solid black; */
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  border-radius: 20px;
}
tr td,
tr th {
  border: 1px solid black;
}

tr td {
  /* border: 1px solid #a4c639 ; */
}

td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  /* background-color: #a4c639 ; */
  /* color: white; */
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
thead {
  border: 1px solid black;
}

.sign-maincontainer {
  display: flex;
  /* border: 2px solid black; */
}

.sign-container {
  border: 1px solid black;
  height: 100px;
  width: 250px;
  position: relative;
}

.sign-heading {
  border: 1px solid black;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 68px;
  padding: 5px;
}

.instructions-container {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}
.patient-sign {
  border: none;
}

.DiseseaNameHighLight {
  /* position: relative;
  left: 45%; */
  /* white-space: nowrap; */
}

.cardStatus {
  white-space: nowrap;
}
/* Additional styles for responsiveness */

.btn-download-pdf {
  background-color: #a4c639;
  border-radius: 10px;
  color: white;
  border: none;
  width: 20%;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 0; /* Adjusted margin for smaller screens */
  cursor: pointer;
}

.group {
  display: flex;

  align-items: center;
  margin: 30px;
}

.input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  color: #0d0c22;
  border: 1px solid black;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #8e8e97;
}

.pdf-download {
}

.input:focus,
.input:hover {
  outline: none;
  border-color: rgba(0, 48, 73, 0.4);
  background-color: #fff;
  box-shadow: 0 0 2px 3px#a4c639;
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}
.diseaseName,
.careTakerNum {
  font-size: 25px;
  margin-left: 5px;
  color: #a4c639;
  font-weight: bold;
}
.uploadedDocuments {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-btns {
  display: flex;
}
.pdf-download-btn {
  background-color: transparent;
  color: #a4c639;
  position: relative;
  left: 1070px;
  bottom: 200px;
  font-size: 40px;
  border: none;
}
.dots {
  /* position: relative;
  right: -1070px;
  display: flex;
  justify-content: end;
  align-items: end;
 bottom: 200px;
 font-size: 40px;
 border: none; */
}

.droupdown {
  position: relative;
  right: -1070px;
  bottom: 200px;
  font-size: 40px;
  border: none;
}
.droupdown-main {
  color: black;
  font-size: 20px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black background for blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Higher z-index to ensure it appears above other elements */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 11.2px;
  height: 11.2px;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 rgba(164, 198, 57, 0.2),
    22.7px 16.5px 0 0 rgba(164, 198, 57, 0.4),
    8.68px 26.6px 0 0 rgba(164, 198, 57, 0.6),
    -8.68px 26.6px 0 0 rgba(164, 198, 57, 0.8), -22.7px 16.5px 0 0 #a4c639;
  animation: spinner-b87k6z 1s infinite linear;
}

@keyframes spinner-b87k6z {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .file-upload-logo,
  .card-file-not-found,
  .card-file-upload {
    right: 0px;
  }

  .maincontainer {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .btn-download-pdf,
  .btn-register-status,
  .btn-register-more {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .patient-table {
    margin: 0px;
  }
  .patient-card {
    padding: 15px;
  }
  .cardStatus {
    white-space: wrap;
  }
  .pdf-download-btn {
    left: 290px;
    top: -150px;
    font-size: 30px;
  }

  .DiseseaNameHighLight span,
  .careTakerNum {
    font-size: 15px;
  }

  tr td,
  tr,
  th {
    font-size: 10px;
  }
  .form-input {
    width: 280px;
  }
  .submit {
    left: 270px;
  }
  .uploadedDocuments {
    width: 180px;
    display: flex;
    align-items: center;
  }
  .uploadedDocuments a,
  .uploadedDocuments img {
    margin: 0px;
  }
  .btn-register-more,
  .btn-register-status {
    padding: 6px 12px; /* Adjusted padding for smaller screens */
    font-size: 12px; /* Adjusted font size for smaller screens */
  }

  .data-btn {
    flex-direction: row; /* Stack buttons horizontally on smaller screens */
    justify-content: space-around; /* Adjust spacing for smaller screens */
  }
}
@media only screen and (max-width: 768px) {
  .pdf-download-btn {
    left: 650px;
  }
  
}

/* Add responsive adjustments for larger screens */
@media screen and (min-width: 768px) {
  .card-file-upload,
  .card-file-not-found {
    right: 200px;
  }

  .patient-card {
    flex-direction: row; /* Adjusted for larger screens */
  }

  .patient-card .patient-details-sidebar {
    left: 0; /* Bring sidebar back to the screen for larger screens */
  }
 
}

/* Add additional styles for responsiveness as needed */

@media only screen and (max-width: 900px) {
  .card-file-upload,
  .card-file-not-found {
    right: 0px;
  }
  .patient-card {
    min-width: 95%;
  }
 
}
